import {graphql, useStaticQuery} from "gatsby";
import React from "react";

import Layout from "../../components/Layout";
import BlogGallery from "../../createdpages/blog/BlogGallery/BlogGallery";

const LaunchYourStore = () => {
  const data = useStaticQuery(graphql`
    {
      allGhostPost(
        filter: {tags: {elemMatch: {slug: {in: ["sales", "marketing"]}}}}
      ) {
        nodes {
          feature_image
          localImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          featured
          id
          tags {
            slug
            name
          }
          title
          og_description
          authors {
            profile_image
            name
          }
          reading_time
          slug
          html
          published_at
        }
      }
    }
  `);
  return (
    <Layout seoData={{title: "Mason | Launch your store"}}>
      <div className='full-bleed layouted bg-gray-200'>
        <div className='coll-header my-24'>
          <h1 style={{color: "#E7544B"}}>Launch your store</h1>
          <br />
          <h3 className='light w-full w-2/4'>
            Get prepared to power-launch your store
          </h3>
        </div>
        <BlogGallery data={data.allGhostPost.nodes} />
      </div>
    </Layout>
  );
};

export default LaunchYourStore;
